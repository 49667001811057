const IMAGES = {
  banner1: require("../img/home/ban1.jpeg"),
  banner2: require("../img/home/ban2.png"),
  banner3: require("../img/home/ban3.webp"),
  banner4: require("../img/home/ban4.jpeg"),
  banner5: require("../img/home/ban5.webp"),
  banner6: require("../img/home/ban6.webp"),
  banner7: require("../img/home/ban7.webp"),
  phonepe: require("../img/phonepe.png"),
  paytm: require("../img/paytm.png"),
  gpay: require("../img/gpay.png"),
  upi: require("../img/upi.webp"),
};

export default IMAGES;
